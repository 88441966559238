// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import cartReducer from './slices/cart';
import bookingReducer from './slices/highcastle/booking';
import clientReducer from './slices/highcastle/client';
import invoiceReducer from './slices/highcastle/invoice';
import performanceReportReducer from './slices/highcastle/performanceReport';
import reviewReducer from './slices/highcastle/review';
import studioReducer from './slices/highcastle/studio';
import menuReducer from './slices/menu';
import snackbarReducer from './slices/snackbar';
import statsReducer from './slices/highcastle/stats';
import clientWalletActivityReducer from './slices/highcastle/clientWalletActivity';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    menu: menuReducer,
    stats: statsReducer,
    booking: bookingReducer,
    studio: studioReducer,
    invoice: invoiceReducer,
    client: clientReducer,
    review: reviewReducer,
    performanceReport: performanceReportReducer,
    clientWalletActivity: clientWalletActivityReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined
    }

    return reducer(state, action)
}

export default rootReducer;
