// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// firebase query
import { getFirestore, query, collection, orderBy, limit, getDocs, where } from "firebase/firestore";

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    walletActivities: [],
    clientInfo: []
};

const converTS = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
)
// .toDateString()

const converTSTime = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
).toTimeString()


const slice = createSlice({
    name: 'clientWalletActivity',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CLIENT WALLET ACTIVITY 
        getClientWalletActivitySuccess(state, action) {
            state.walletActivities = action.payload;
        },

        // GET CLIENT INFO
        getClientsInfoSuccess(state, action) {
            state.clientInfo = action.payload;
        },


    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const db = getFirestore()



export function getClientActivityAll() {
    return async () => {
        try {

            const q = query(collection(db, "Wallet"), where("activityDate", '!=', ""));

            const documentSnapshots = await getDocs(q);


            const customerWalletActivity = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                const detailData = {
                    ...data,
                    activityDate: converTS(data.activityDate),
                    activityTime: converTSTime(data.activityDate),
                    activity: data?.action,
                    senangPayRefID: data?.pg_transactionRef,
                    transaction: parseFloat(data.transactedValue).toFixed(2),
                    hcWalletBalance: parseFloat(data.hcWalletBalance).toFixed(2),
                }


                customerWalletActivity.push(detailData)
            }

            dispatch(slice.actions.getClientWalletActivitySuccess(customerWalletActivity));
        } catch (error) {
            console.log('\n error -->', error, '\n')
            dispatch(slice.actions.hasError(error));
        }
    };
}




export function getClientActivityByEmail(customerEmail) {
    return async () => {
        try {

            const q = query(collection(db, "Wallet"), where("userEmail", '==', customerEmail));

            const documentSnapshots = await getDocs(q);


            const customerWalletActivity = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                customerWalletActivity.push(data)
            }

            dispatch(slice.actions.getClientWalletActivitySuccess(customerWalletActivity));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getClient(customerEmail) {
    return async () => {
        try {

            const q = query(collection(db, "User"), where("email", '==', customerEmail));

            const documentSnapshots = await getDocs(q);

            const clientInfo = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                clientInfo.push(data)
            }
            dispatch(slice.actions.getClientsInfoSuccess(clientInfo));
        } catch (error) {
            console.log('error', error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
