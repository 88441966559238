// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconCalendarEvent, IconTable, IconMoodSmile, IconTableImport, IconReportMoney, IconStar, IconReport, IconReportAnalytics, IconWallet, IconBrandGoogleAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconBrandGoogleAnalytics,
    IconHome,
    IconCalendarEvent,
    IconTable,
    IconMoodSmile,
    IconTableImport,
    IconReportMoney,
    IconStar,
    IconReport, 
    IconReportAnalytics,
    IconWallet

};



// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const highcastle = {
    id: 'highcastle-menu',
    type: 'group',
    children: [
        {
            id: 'home-castle-page',
            title: <FormattedMessage id="Home" />,
            type: 'item',
            url: '/home',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        // {
        //     id: 'booking-data',
        //     title: <FormattedMessage id="Booking Data" />,
        //     type: 'item',
        //     url: '/data/booking',
        //     icon: icons.IconReport,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'sales-data',
        //     title: <FormattedMessage id="Sales Data" />,
        //     type: 'item',
        //     url: '/data/sales',
        //     breadcrumbs: false,
        //     icon: icons.IconReportMoney
        // },
        {
            id: 'studios-schedule-data',
            title: <FormattedMessage id="Studios Schedule " />,
            type: 'item',
            url: '/studios-schedule-page',
            breadcrumbs: false,
            icon: icons.IconCalendarEvent,
        },

        {
            id: 'performance-data',
            title: <FormattedMessage id="Performance Data" />,
            type: 'item',
            url: '/performance-report',
            breadcrumbs: false,
            icon: icons.IconReportAnalytics
        },

        {
            id: 'client-page',
            title: <FormattedMessage id="Client" />,
            type: 'item',
            url: '/client',
            icon: icons.IconMoodSmile,
            breadcrumbs: false
        },
        {
            id: 'client-activity-page',
            title: <FormattedMessage id="Client Wallet Activity" />,
            type: 'item',
            url: '/client-wallet-activity',
            icon: icons.IconWallet,
            breadcrumbs: false
        },
        // {
        //     id: 'commodity-page',
        //     title: <FormattedMessage id="Commodity" />,
        //     type: 'item',
        //     url: '/commodity-report',
        //     icon: icons.IconBrandGoogleAnalytics,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'review-page',
        //     title: <FormattedMessage id="Review" />,
        //     type: 'item',
        //     url: '/review',
        //     icon: icons.IconStar,
        //     breadcrumbs: false
        // },

    ]
};

export default highcastle;
