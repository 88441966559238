// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconCalendarEvent, IconTable, IconMoodSmile, IconTableImport, IconReportMoney , IconStar, IconChartDots} from '@tabler/icons';

// constant
const icons = {
    IconHome,
    IconCalendarEvent,
    IconTable,
    IconMoodSmile,
    IconTableImport,
    IconReportMoney,
    IconStar,
    IconChartDots

};



// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const highcastle = {
    id: 'highcastle-menu',
    type: 'group',
    children: [
        {
            id: 'home-castle-page',
            title: <FormattedMessage id="Home" />,
            type: 'item',
            url: '/home',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'statistic-page',
            title: <FormattedMessage id="Statistic" />,
            type: 'item',
            url: '/statistics',
            icon: icons.IconChartDots,
            breadcrumbs: false
        },
        {
            id: 'schedule-page',
            title: <FormattedMessage id="Calendar" />,
            type: 'item',
            url: '/schedule-page',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'booking-data',
            title: <FormattedMessage id="Booking-Data" />,
            type: 'item',
            url: '/data/booking',
            breadcrumbs: false,
            icon: icons.IconTableImport
        },
        {
            id: 'sales-data',
            title: <FormattedMessage id="Sales-Data" />,
            type: 'item',
            url: '/data/sales',
            breadcrumbs: false,
            icon: icons.IconReportMoney
        },       
        // {
        //     id: 'client-page',
        //     title: <FormattedMessage id="Client" />,
        //     type: 'item',
        //     url: '/client',
        //     icon: icons.IconMoodSmile,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'review-page',
        //     title: <FormattedMessage id="Review" />,
        //     type: 'item',
        //     url: '/review',
        //     icon: icons.IconStar,
        //     breadcrumbs: false
        // },

    ]
};

export default highcastle;
