
import highcastle from './highcastle.menu'


// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
    items: [
        highcastle,
    ]
};

export default menuItems;
