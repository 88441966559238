import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items/index';
import menuItemAdmin from 'menu-items/index.admin';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const selectMenu =(userType)=>{
    if(userType==='Admin') return menuItemAdmin
    return menuItem
}

const MenuList = () => {

    const { user } = useAuth();

    const navItems = selectMenu(user?.userType).items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
