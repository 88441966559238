import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

// home routing
const Home = Loadable(lazy(() => import('views/home')));

// statistic routing 
const Statistics = Loadable(lazy(() => import('views/statistics')));


// schedule routing 
const Schedule = Loadable(lazy(() => import('views/schedule/calendar')));

// schedule admin routing 
const ScheduleAdmin = Loadable(lazy(() => import('views/schedule-admin/calendar')));

// booking routing 
const BookingList = Loadable(lazy(() => import('views/data/booking/bookingListNew')));

// sales routing 
const SalesList = Loadable(lazy(() => import('views/data/sales/salesListNew')));

// client routing 
// const ClientList = Loadable(lazy(() => import('views/client/ClientList')));
const ClientList = Loadable(lazy(() => import('views/client/clientListNew')));

// client activity routing 
const ClientActivityList = Loadable(lazy(() => import('views/clientWalletActivity/clientWalletActivityList')));

// review routing 
const ReviewList = Loadable(lazy(() => import('views/review/CustomerReview/ReviewPage')));

// performance routing 
// const PerformanceReportList = Loadable(lazy(() => import('views/performanceReport/performanceReportList')));
const PerformanceReportList = Loadable(lazy(() => import('views/performanceReport/performanceReportListNew')));

// commodity routing 
const CommodityList = Loadable(lazy(() => import('views/commodityReport/commodityReportList')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [

        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/statistics',
            element: <Statistics />
        },
        {
            path: '/schedule-page',
            element: <Schedule />
        },
        {
            path: '/studios-schedule-page',
            element: <ScheduleAdmin />
        },
        {
            path: '/data-page',
            element: <Home />
        },
        {
            path: '/data/booking',
            element: <BookingList />
        },
        {
            path: '/data/sales',
            element: <SalesList />
        },
        {
            path: '/client',
            element: <ClientList />
        },
        {
            path: '/review',
            element: <ReviewList />
        },
        {
            path: '/client-wallet-activity',
            element: <ClientActivityList />
        },
        {
            path: '/performance-report',
            element: <PerformanceReportList />
        },
        {
            path: '/commodity-report',
            element: <CommodityList />
        }


    ]
};

export default MainRoutes;
