// third-party
import { createSlice } from '@reduxjs/toolkit';

// firebase query
import { getFirestore, query, collection, orderBy, limit, getDocs, where, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// ----------------------------------------------------------------------

const converTS = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
)
// .toDateString()

const converTSTime = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
).toTimeString()

const initialState = {
    error: null,
    bookings: [],
    refresh: false,
    notificationsSnippet: []
};

const slice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET BOOKING
        getBookingsSuccess(state, action) {
            state.bookings = action.payload;
        },
        // GET LATEST BOOKING SINCE LAST VISIT
        getLatestBookingsSinceLastVisitForStudioSuccess(state, action) {
            const data = [];
            const el = action.payload

            for (let i = 0; i < action.payload.length; i += 1) {

                if (el[i]?.bookedRead === false) {
                    data.push({
                        id: el[i]?.id,
                        bookingDate: el[i]?.bookingDate,
                        roomName: el[i]?.roomName,
                        bookedRead: el[i]?.bookedRead,
                        startHour: el[i]?.startHour,
                        endHour: el[i]?.endHour,
                        userEmail: el[i]?.userEmail,
                        date: el[i]?.date

                    })
                }

            }

            state.notificationsSnippet = data;



        },
        // GET BOOKING FOR STUDIO
        getBookingsForStudioSuccess(state, action) {
            state.bookings = action.payload;
        },
        // ADD BLOCKING SUCCESS
        addBlockingSuccess(state, action) {
            // state.bookings = action.payload;
        },
        // ADD BOOKING REFRESH
        bookingRefresh(state, action) {
            state.refresh = action.payload;
        },
    }
});

const db = getFirestore()


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBookings() {
    return async () => {
        try {

            const q = query(collection(db, "Booking"), orderBy('bookingDate'));
            const documentSnapshots = await getDocs(q);

            const bookings = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {

                const data = documentSnapshots.docs[i].data()
                // let color = data?.bcolor 
                bookings.push(data)
            }



            dispatch(slice.actions.getBookingsSuccess(bookings));
            // dispatch(slice.actions.getLatestBookingsSinceLastVisitForStudioSuccess(bookings))
            dispatch(slice.actions.bookingRefresh(false));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetBookings() {
    return async () => {
        try {

            dispatch(slice.actions.getBookingsSuccess([]));
            dispatch(slice.actions.getLatestBookingsSinceLastVisitForStudioSuccess([]))
            dispatch(slice.actions.bookingRefresh(false));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBookingsForStudio(studioId) {
    return async () => {
        try {
            const q = query(collection(db, "Booking"), where("studioID", "==", studioId),);
            const documentSnapshots = await getDocs(q);

            const bookings = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                const detailData = {
                    ...data,
                    reference: data?.invoiceNo,
                    bookingDate: converTS(data.date),
                    bookingDuration: Number(data?.endHour) - Number(data?.startHour),
                    paymentStatus: data?.status,
                    bookingStatus: data?.userStatus || "default",
                }

                bookings.push(detailData)
            }

            dispatch(slice.actions.getBookingsForStudioSuccess(bookings));
            dispatch(slice.actions.getLatestBookingsSinceLastVisitForStudioSuccess(bookings))
            dispatch(slice.actions.bookingRefresh(false));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addBlockingOfflineTime(data) {
    return async () => {
        try {

            const addBlockingResp = await addDoc(collection(db, "Booking"), data);
            if (addBlockingResp?.id) await updateDoc(doc(db, "Booking", addBlockingResp.id), { id: addBlockingResp?.id });
            dispatch(slice.actions.addBlockingSuccess(addBlockingResp?.data));
            dispatch(slice.actions.bookingRefresh(true));
        } catch (error) {
            console.log('add blocking error', error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBooking(bookingId, data) {
    return async () => {
        try {
            await updateDoc(doc(db, "Booking", bookingId), data);

            // 'default', 'arrived', 'no show'
            if (data?.userStatus === 'arrived' || data?.userStatus === 'no show' || data?.userStatus === 'finish') {
                const q = query(collection(db, "PerformanceData"), where("bookingID", "==", bookingId),);
                const docPerfDataID = await (await getDocs(q)).docs[0]?.id;
                if (docPerfDataID) {
                    await updateDoc(doc(db, "PerformanceData", docPerfDataID), { userStatus: data?.userStatus });
                }

            }

            dispatch(slice.actions.bookingRefresh(true));
        } catch (error) {
            console.log('update booking error', error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteBooking(bookingId) {
    return async () => {
        try {
            // await updateDoc(doc(db, "Booking", bookingId), data);
            const deleteStudoResp = await deleteDoc(doc(db, `Booking/${bookingId}`,));

            dispatch(slice.actions.bookingRefresh(true));
        } catch (error) {
            // console.log('update booking error', error)
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function findLatestBooking(studioId, lastVisit, now) {
    return async () => {
        try {

            const bookingRef = collection(db, 'Booking')

            const q = query(bookingRef, where("studioID", "==", studioId), orderBy('bookingDate'));


            const documentSnapshots = await getDocs(q);

            console.log('\n====\n')
            console.log('documentSnapshots last visit compound q >> ', documentSnapshots)
            console.log('\n====\n')

            const bookings = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {

                const data = documentSnapshots.docs[i].data()
                bookings.push(data)
            }
            dispatch(slice.actions.getLatestBookingsSinceLastVisitForStudioSuccess(bookings))

            // dispatch(slice.actions.bookingRefresh(true));
        } catch (error) {
            console.log('update booking error', error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markNotificationRead(bookingIdsArray,) {
    return async () => {
        try {
            const promiseUpdateDocNotificationRead = []

            for (let i = 0; i < bookingIdsArray.length; i += 1) {
                promiseUpdateDocNotificationRead.push(updateDoc(doc(db, `Booking/${bookingIdsArray[i]}`,), { bookedRead: true }))
            }

            Promise.all(promiseUpdateDocNotificationRead).then(values => {
                dispatch(slice.actions.bookingRefresh(true));
            }).catch(e => {

            }).finally(el => {
                dispatch(slice.actions.bookingRefresh(true));
            })


        } catch (error) {

            dispatch(slice.actions.hasError(error));
        }
    };
}

