// third-party
import { createSlice } from '@reduxjs/toolkit';

// firebase query
import { getFirestore, query, collection, orderBy, limit, getDocs, where, addDoc, updateDoc, doc } from "firebase/firestore";

// project imports
import { dispatch } from '../../index';
import { getDay, getWeekOfMonth, format, getMonth } from 'date-fns'


const converTS = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
)

const customGetWeekOfMonth2 = timestamp => getWeekOfMonth((new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
)))

const getMonthOfYear = timestamp => getMonth(new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
))

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    bookings: [],
    sales: [],
    salesAmount: 0,
    bookingAmount: [],
    rangeTypeBooking: '',
    rangeTypeSales: '',
    refresh: false,
    notificationsSnippet: [],
    chartDataBookingWeekly: {
        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        series: [
            // {
            //     name: 'Online',
            //     data: [35, 125, 35, 35, 35, 80, 35]
            // },
            // {
            //     name: 'Offline',
            //     data: [35, 15, 15, 35, 65, 40, 80,]
            // },
            // {
            //     name: 'Block',
            //     data: [35, 145, 35, 35, 20, 105, 100]
            // }
        ]
    },
    chartDataBookingMonthly: {
        categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7'],
        series: [
            // {
            //     name: 'Online',
            //     data: [35, 125, 35, 35, 35, 80, 35]
            // },
            // {
            //     name: 'Offline',
            //     data: [35, 15, 15, 35, 65, 40, 80,]
            // },
            // {
            //     name: 'Block',
            //     data: [35, 145, 35, 35, 20, 105, 100]
            // }
        ]
    },
    chartDataBookingYearly: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
            // {
            //     name: 'Online',
            //     data: [35, 125, 35, 35, 35, 80, 35, 20, 35, 45, 15, 75]
            // },
            // {
            //     name: 'Offline',
            //     data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85, 25, 75]
            // },
            // {
            //     name: 'Block',
            //     data: [35, 145, 35, 35, 20, 105, 100, 10, 65, 45, 30, 10]
            // }
        ]
    },

    chartDataSalesWeekly: {
        categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        series: [
            {
                name: 'Online',
                // data: [35, 125, 35, 35, 35, 80, 35]
            },

        ]
    },
    chartDataSalesMonthly: {
        categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7'],
        series: [
            {
                name: 'Online',
                // data: [35, 125, 35, 35, 35, 80, 35]
            },

        ]
    },
    chartDataSalesYearly: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        series: [
            {
                name: 'Online',
                // data: [35, 125, 35, 35, 35, 80, 35, 20, 35, 45, 15, 75]
            },

        ]
    },

};



const slice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET BOOKING
        getBookingsSuccess(state, action) {
            state.bookings = action.payload;
        },
        // GET BOOKING FOR STUDIO
        getBookingsForStudioWithRangeSuccess(state, action) {
            state.bookings = action.payload;
        },
        // GET INVOICE FOR STUDIO
        getSalesInvoiceForStudioWithRangeSuccess(state, action) {
            state.sales = action.payload;
        },
        // GET SALES AMOUNT FOR STUDIO
        getSalesAmountTotalForStudioWithRangeSuccess(state, action) {
            state.salesAmount = action.payload;
        },
        // ADD BOOKING REFRESH
        bookingRefresh(state, action) {
            console.log('called refresh ->', action.payload)
            state.refresh = action.payload;
        },
        // SET RANGE TYPE BOOKING
        setRangeTypeBookingSuccess(state, action) {
            state.rangeTypeBooking = action.payload;
        },
        // SET RANGE TYPE SALES
        setRangeTypeSalesSuccess(state, action) {
            state.rangeTypeSales = action.payload;
        },
        getStatsBookingsForStudioWithRangeSuccessWeekly(state, action) {

            const arrayCountOnline = [0, 0, 0, 0, 0, 0, 0];
            const arrayCountOffline = [0, 0, 0, 0, 0, 0, 0];
            const arrayCountBlocking = [0, 0, 0, 0, 0, 0, 0];

            action.payload.forEach((eachBooking, index) => {

                // mapped to => monday == 0 , tuesday == 1 , wednesday == 2 , thursday == 3 , friday == 4 , saturday == 5 , sunday == 6
                // refactor back, since library most starts week at sunday
                // const getDay = mapDateFNSResultToProperArray(converTS(eachBooking?.date).getDay() - 1)
                const getDay = converTS(eachBooking?.date).getDay()


                if (eachBooking.title === 'Online') {
                    // eslint-disable-next-line no-use-before-define

                    if (index < 5) {
                        console.log('\n===\n')
                        console.log('eachBooking?.date ::', converTS(eachBooking?.date), " :: day of the Week", converTS(eachBooking?.date).getDay())
                        console.log('\n===\n')
                    }

                    arrayCountOnline[converTS(eachBooking?.date).getDay()] = arrayCountOnline[converTS(eachBooking?.date).getDay()] + 1

                } else if (eachBooking.title === 'Offline') {
                    // eslint-disable-next-line no-use-before-define
                    arrayCountOffline[converTS(eachBooking?.date).getDay()] = arrayCountOffline[converTS(eachBooking?.date).getDay()] + 1

                } else {
                    // eslint-disable-next-line no-use-before-define
                    arrayCountBlocking[converTS(eachBooking?.date).getDay()] = arrayCountBlocking[converTS(eachBooking?.date).getDay()] + 1

                }



            })

            state.chartDataBookingWeekly.series = [
                {
                    name: 'Online',
                    data: arrayCountOnline,
                    fillColor: '#EB8C87',

                },
                {
                    name: 'Offline',
                    data: arrayCountOffline
                },
                {
                    name: 'Block',
                    data: arrayCountBlocking
                }
            ]

            

        },
        getStatsBookingsForStudioWithRangeSuccessMonthly(state, action) {
            

            const arrayCountOnline = [0, 0, 0, 0, 0, 0, 0];
            const arrayCountOffline = [0, 0, 0, 0, 0, 0, 0];
            const arrayCountBlocking = [0, 0, 0, 0, 0, 0, 0];

            action.payload.forEach((eachBooking, index) => {

                // mapped to Get the week of the month of the given date.

                // const getWeekOfMonth = mapDateFNSResultToProperArray(converTS(eachBooking?.date).getWeekOfMonth())

                if (eachBooking.title === 'Online') {
                    // eslint-disable-next-line no-use-before-define
                    // if (index < 10) {
                    //     console.log('\n===\n')
                    //     console.log('eachBooking?.date ::', converTS(eachBooking?.date), " :: week of Month", customGetWeekOfMonth2(eachBooking?.date))
                    //     console.log('\n===\n')
                    // }

                    arrayCountOnline[customGetWeekOfMonth2(eachBooking?.date)] = arrayCountOnline[customGetWeekOfMonth2(eachBooking?.date)] + 1

                } else if (eachBooking.title === 'Offline') {
                    // eslint-disable-next-line no-use-before-define
                    arrayCountOffline[customGetWeekOfMonth2(eachBooking?.date)] = arrayCountOffline[customGetWeekOfMonth2(eachBooking?.date)] + 1
                } else {
                    // eslint-disable-next-line no-use-before-define
                    arrayCountBlocking[customGetWeekOfMonth2(eachBooking?.date)] = arrayCountBlocking[customGetWeekOfMonth2(eachBooking?.date)] + 1
                }

            })

            state.chartDataBookingMonthly.series = [
                {
                    name: 'Online',
                    data: arrayCountOnline,
                    fillColor: '#EB8C87',

                },
                {
                    name: 'Offline',
                    data: arrayCountOffline
                },
                {
                    name: 'Block',
                    data: arrayCountBlocking
                }
            ]

            

        },
        getStatsBookingsForStudioWithRangeSuccessYearly(state, action) {
            
            const arrayCountOnline = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const arrayCountOffline = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            const arrayCountBlocking = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            action.payload.forEach((eachBooking, index) => {

                // mapped to Get the week of the month of the given date.

                // const getWeekOfMonth = mapDateFNSResultToProperArray(converTS(eachBooking?.date).getWeekOfMonth())

                if (eachBooking.title === 'Online') {
                    // eslint-disable-next-line no-use-before-define
                    // if (index < 5) {
                    //     console.log('\n== FIRST 5 =\n')
                    //     console.log('eachBooking?.date ::', converTS(eachBooking?.date), " :: week of Month", getMonthOfYear(eachBooking?.date))
                    //     console.log('\n===\n')
                    // }

                    // if (index > 25) {
                    //     console.log('\n== LAST 5 =\n')
                    //     console.log('eachBooking?.date ::', converTS(eachBooking?.date), " :: week of Month", getMonthOfYear(eachBooking?.date))
                    //     console.log('\n===\n')
                    // }

                    arrayCountOnline[getMonthOfYear(eachBooking?.date)] = arrayCountOnline[getMonthOfYear(eachBooking?.date)] + 1
                } else if (eachBooking.title === 'Offline') {
                    // eslint-disable-next-line no-use-before-define
                    arrayCountOffline[getMonthOfYear(eachBooking?.date)] = arrayCountOffline[getMonthOfYear(eachBooking?.date)] + 1
                } else {
                    // eslint-disable-next-line no-use-before-define
                    arrayCountBlocking[getMonthOfYear(eachBooking?.date)] = arrayCountBlocking[getMonthOfYear(eachBooking?.date)] + 1
                }

            })

            state.chartDataBookingYearly.series = [
                {
                    name: 'Online',
                    data: arrayCountOnline,

                },
                {
                    name: 'Offline',
                    data: arrayCountOffline
                },
                {
                    name: 'Block',
                    data: arrayCountBlocking
                }
            ]

            

        },

        getStatsSalesForStudioWithRangeSuccessWeekly(state, action) {

            

            const arrayCountOnline = [0, 0, 0, 0, 0, 0, 0];

            action.payload.forEach((eachBooking, index) => {

                arrayCountOnline[converTS(eachBooking?.bookedDate).getDay()] = arrayCountOnline[converTS(eachBooking?.bookedDate).getDay()] + Number(eachBooking?.paymentAmount)

            })

            state.chartDataSalesWeekly.series = [
                {
                    name: 'Online',
                    data: arrayCountOnline,
                    fillColor: '#EB8C87',

                },
            ]



        },
        getStatsSalesForStudioWithRangeSuccessMonthly(state, action) {

            

            const arrayCountOnline = [0, 0, 0, 0, 0, 0, 0];


            action.payload.forEach((eachBooking, index) => {

                arrayCountOnline[customGetWeekOfMonth2(eachBooking?.bookedDate)] = arrayCountOnline[customGetWeekOfMonth2(eachBooking?.bookedDate)] + Number(eachBooking?.paymentAmount)


            })

            state.chartDataSalesMonthly.series = [
                {
                    name: 'Online',
                    data: arrayCountOnline,
                    fillColor: '#EB8C87',

                },

            ]

            

        },
        getStatsSalesForStudioWithRangeSuccessYearly(state, action) {

            

            const arrayCountOnline = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            action.payload.forEach((eachBooking, index) => {

                arrayCountOnline[getMonthOfYear(eachBooking?.bookedDate)] = arrayCountOnline[getMonthOfYear(eachBooking?.bookedDate)] + Number(eachBooking?.paymentAmount)


            })

            state.chartDataSalesYearly.series = [
                {
                    name: 'Online',
                    data: arrayCountOnline,

                },

            ]

            

        },
        
    }
});

const db = getFirestore()


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------




export function getBookingsForStudioByRange(studioId, firstDay, lastDay, type) {
    return async () => {
        try {


            dispatch(slice.actions.bookingRefresh(true))

            const q = query(collection(db, "Booking"), where("studioID", "==", studioId), where("date", ">=", firstDay), where("date", "<=", lastDay));
            const documentSnapshots = await getDocs(q);

            const bookings = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()
                bookings.push(data)
            }

            dispatch(slice.actions.getBookingsForStudioWithRangeSuccess(bookings));
            if (type === 'Week') dispatch(slice.actions.getStatsBookingsForStudioWithRangeSuccessWeekly(bookings));
            else if (type === 'Month') dispatch(slice.actions.getStatsBookingsForStudioWithRangeSuccessMonthly(bookings));
            else if (type === 'Year') dispatch(slice.actions.getStatsBookingsForStudioWithRangeSuccessYearly(bookings));
            dispatch(slice.actions.bookingRefresh(false))

        } catch (error) {
            dispatch(slice.actions.bookingRefresh(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBookingsForAdminByRange(firstDay, lastDay, type) {
    return async () => {
        try {
            dispatch(slice.actions.bookingRefresh(true))

            const q = query(collection(db, "Booking"), where("date", ">=", firstDay), where("date", "<=", lastDay));
            const documentSnapshots = await getDocs(q);

            const bookings = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()
                bookings.push(data)
            }

            dispatch(slice.actions.getBookingsForStudioWithRangeSuccess(bookings));
            if (type === 'Week') dispatch(slice.actions.getStatsBookingsForStudioWithRangeSuccessWeekly(bookings));
            else if (type === 'Month') dispatch(slice.actions.getStatsBookingsForStudioWithRangeSuccessMonthly(bookings));
            else if (type === 'Year') dispatch(slice.actions.getStatsBookingsForStudioWithRangeSuccessYearly(bookings));
            dispatch(slice.actions.bookingRefresh(false))

        } catch (error) {
            dispatch(slice.actions.bookingRefresh(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getSalesInvoiceForStudioByRange(studioId, firstDay, lastDay, type) {
    return async () => {
        try {

            dispatch(slice.actions.bookingRefresh(true))
            const q = query(collection(db, "Invoice"), where("bookedStudioID", "==", studioId), where("bookedDate", ">=", firstDay), where("bookedDate", "<=", lastDay));
            const documentSnapshots = await getDocs(q);
          

            const sales = []
            let salesAmount = 0

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()
                sales.push(data)
                salesAmount += parseFloat(documentSnapshots.docs[i].data()?.paymentAmount)
            }

            dispatch(slice.actions.getSalesInvoiceForStudioWithRangeSuccess(sales));
            dispatch(slice.actions.getSalesAmountTotalForStudioWithRangeSuccess(salesAmount));
            if (type === 'Week') dispatch(slice.actions.getStatsSalesForStudioWithRangeSuccessWeekly(sales));
            else if (type === 'Month') dispatch(slice.actions.getStatsSalesForStudioWithRangeSuccessMonthly(sales));
            else if (type === 'Year') dispatch(slice.actions.getStatsSalesForStudioWithRangeSuccessYearly(sales));
            dispatch(slice.actions.bookingRefresh(false))



        } catch (error) {
            dispatch(slice.actions.bookingRefresh(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSalesInvoiceForAdminByRange(firstDay, lastDay, type) {
    return async () => {
        try {

            dispatch(slice.actions.bookingRefresh(true))
            const q = query(collection(db, "Invoice"), where("bookedDate", ">=", firstDay), where("bookedDate", "<=", lastDay));
            const documentSnapshots = await getDocs(q);


            const sales = []
            let salesAmount = 0

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()
                sales.push(data)
                salesAmount += parseFloat(documentSnapshots.docs[i].data()?.paymentAmount)
            }

            dispatch(slice.actions.getSalesInvoiceForStudioWithRangeSuccess(sales));
            dispatch(slice.actions.getSalesAmountTotalForStudioWithRangeSuccess(salesAmount));
            if (type === 'Week') dispatch(slice.actions.getStatsSalesForStudioWithRangeSuccessWeekly(sales));
            else if (type === 'Month') dispatch(slice.actions.getStatsSalesForStudioWithRangeSuccessMonthly(sales));
            else if (type === 'Year') dispatch(slice.actions.getStatsSalesForStudioWithRangeSuccessYearly(sales));
            dispatch(slice.actions.bookingRefresh(false))



        } catch (error) {
            dispatch(slice.actions.bookingRefresh(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setRangeTypeBooking(type) {
    return async () => {
        try {

            dispatch(slice.actions.setRangeTypeBookingSuccess(type));

        } catch (error) {

            dispatch(slice.actions.hasError(error));
        }
    };
}


export function setRangeTypeSales(type) {
    return async () => {
        try {

            dispatch(slice.actions.setRangeTypeSalesSuccess(type));

        } catch (error) {

            dispatch(slice.actions.hasError(error));
        }
    };
}
