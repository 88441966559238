// third-party
import { createSlice } from '@reduxjs/toolkit';

// firebase query
import { getFirestore, query, collection, orderBy, limit, getDocs, where } from "firebase/firestore";

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// ----------------------------------------------------------------------

const converTS = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
)
// .toDateString()

const converTSTime = timestamp => new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
).toTimeString()

const initialState = {
    error: null,
    invoices: [],
    clients: []

};

const slice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET INVOICE
        getInvoicesSuccess(state, action) {
            state.invoices = action.payload;
        },

        // GET INVOICE
        getInvoiceForStudioSuccess(state, action) {
            state.invoices = action.payload;
        },

        // GET PARTICULAR INVOICE
        getInvoiceForOneBookingSuccess(state, action) {
            state.rooms = action.payload;
        }
    }
});

const db = getFirestore()


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoicesForStudio(studioId) {
    return async () => {
        try {

            const q = query(collection(db, "Invoice"), where("bookedStudioID", "==", studioId));

            const documentSnapshots = await getDocs(q);

            const invoices = []
            const clients = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                const detailData = {
                    ...data,
                    id: i + 1,
                    reference: data?.invoiceNo,
                    roomName: data?.bookedRoom,
                    bookingDate: converTS(data?.bookedDate),
                    bookingDuration: Number(data?.bookedEndHour) - Number(data?.bookedStartHour),
                    bookingPrice: parseFloat(data?.paymentAmount).toFixed(2),
                    // paymentStatus: data?.status,
                    // bookingStatus: data?.userStatus || "default",
                }

                invoices.push(detailData)
            }

            dispatch(slice.actions.getInvoiceForStudioSuccess(invoices));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoices() {
    return async () => {
        try {

            const q = query(collection(db, "Invoice"));

            const documentSnapshots = await getDocs(q);

            const invoices = []
            const clients = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                invoices.push(data)
            }

            dispatch(slice.actions.getInvoicesSuccess(invoices));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetInvoices() {
    return async () => {
        try {
        
            dispatch(slice.actions.getInvoicesSuccess([]));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getProductReviews() {
    return async () => {
        try {
            const response = await axios.get('/api/customer/product/reviews');
            dispatch(slice.actions.getProductReviewsSuccess(response.data.productreviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
