import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import {FIREBASE_API } from 'config';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useDispatch } from 'react-redux';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const db = getFirestore()

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    // console.log('initial accountReducer', state)

    const dispatchReset = useDispatch()

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async(user) => {
                if (user) {

                    // pull user type from firebase collection
                    const q = query(collection(db, "StudioUser"), where("email", "==", user?.email));
                    
                    const querySnapshot = await getDocs(q)
    

                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName || '',
                                userType: querySnapshot.docs[0]?.data()?.type || 'studio'
                            }
                        }
                    });
                } else {
                    // dispatchReset()
                    dispatch({
                        type: LOGOUT
                    });
                   
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email, password) => {

        console.log('firebase context trigger')

        return firebase.auth().signInWithEmailAndPassword(email, password)
    };

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: "select_account"
        });

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => { };
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => { },
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
