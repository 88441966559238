
import highcastle from './highcastle.menu.admin'


// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
    items: [
        highcastle,
    ]
};

export default menuItems;
