export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {

    // apiKey: "AIzaSyA0JewggNUonV0YnzKJsZeQKiM95s1lCds",
    // authDomain: "high-castle-dev.firebaseapp.com",
    // databaseURL: "https://high-castle-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "high-castle-dev",
    // storageBucket: "high-castle-dev.appspot.com",
    // messagingSenderId: "861587564433",
    // appId: "1:861587564433:web:8348de6daff2b190115c30",
    // measurementId: 'G-MGJHSL8XW3',

    apiKey: "AIzaSyD_VW_55kCdoR611sZZ_Z1gxx5eXJUPrUU",
    authDomain: "high-castle-prod.firebaseapp.com",
    projectId: "high-castle-prod",
    storageBucket: "high-castle-prod.appspot.com",
    messagingSenderId: "564080589837",
    appId: "1:564080589837:web:4c8981c00bc7cc64b5a944",
    measurementId: "G-Z78874CK25"
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard/default';

export const HOME_PATH = '/home';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
