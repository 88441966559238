// third-party
import { createSlice } from '@reduxjs/toolkit';

// firebase query
import { getFirestore, query, collection, orderBy, limit, getDocs, where } from "firebase/firestore";

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    reviews: [],
    averageRating: null
    // clients:[]

};

const slice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET REVIEWS
        getReviewsSuccess(state, action) {
            state.reviews = action.payload;
        },

        // GET REVIEWS FOR STUDIO
        getReviewsForStudioSuccess(state, action) {
            state.reviews = action.payload;
        },
        // SET AVERAGE RATING FOR STUDIO
        settRatingForStudioSuccess(state, action) {
            state.averageRating = action.payload;
        },

        // // GET PARTICULAR INVOICE
        // getInvoiceForOneBookingSuccess(state,action){
        //     state.rooms = action.payload;
        // }
    }
});

const db = getFirestore()


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getReviews() {
    return async () => {
        try {

            const q = query(collection(db, "Review"));

            const documentSnapshots = await getDocs(q);

            const reviews = []
            let averageRating = 0;


            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()
                averageRating += data?.starRating
                reviews.push(data)
            }

            averageRating = (averageRating / documentSnapshots.docs.length) / 20

            dispatch(slice.actions.getReviewsSuccess(reviews));            

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getReviewsForStudio(studioId) {
    return async () => {
        try {

            const q = query(collection(db, "Review"), where("studioID", "==", studioId));

            const documentSnapshots = await getDocs(q);

            const reviews = []
            let averageRating = 0;


            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()
                averageRating += data?.starRating
                reviews.push(data)
            }

            averageRating = (averageRating / documentSnapshots.docs.length) / 20

            dispatch(slice.actions.getReviewsForStudioSuccess(reviews));
            dispatch(slice.actions.settRatingForStudioSuccess(averageRating));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvoices() {
    return async () => {
        try {

            const q = query(collection(db, "Invoice"));

            const documentSnapshots = await getDocs(q);

            const invoices = []
            const clients = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                invoices.push(data)
            }

            dispatch(slice.actions.getInvoicesSuccess(invoices));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getProductReviews() {
    return async () => {
        try {
            const response = await axios.get('/api/customer/product/reviews');
            dispatch(slice.actions.getProductReviewsSuccess(response.data.productreviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
