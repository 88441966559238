// third-party
import { createSlice } from '@reduxjs/toolkit';
import { v4 as UIDV4 } from 'uuid';

// firebase query
import { getFirestore, query, collection, orderBy, limit, getDocs, where, addDoc, setDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";


// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    studios: [],
    allStudios:[],
    studioPictures: [],
    studioAddStatus: false,
    refresh: false,
    studioIds: [],
    rooms: [],
    // lastVisit: null,

};

const slice = createSlice({
    name: 'studio',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET STUDIOS
        getStudiosSuccess(state, action) {
            state.studios = action.payload;

            // SETUP STUDIOS IDS TO PULL BOOKING DATA LATER
            const studiosIds = []
            state.studios.forEach(el => {
                studiosIds.push(el.id.trim())
            })

            state.studioIds = studiosIds

            // if(state.studioAddStatus)
        },
         // GET ALL STUDIOS
         getAllStudiosSuccess(state, action) {
            state.allStudios = action.payload;
            // if(state.studioAddStatus)
        },

        // // SET LAST VISIT STUDIOS HOME
        // setLastVisitSuccess(state, action) {
        //     state.lastVisit = action.payload;

        // },

        // ADD STUDIO SUCCESS
        addStudiosSuccess(state, action) {
            state.studioAddStatus = action.payload;
        },

        // ADD STUDIO SUCCESS
        studioRefresh(state, action) {
            state.refresh = action.payload;
        },


        // ADD STUDIO FAILED OR RESET
        addStudiosReset(state, action) {
            state.studioAddStatus = action.payload;
        },

        // ADD STUDIO PICTURE
        addStudioPictureSuccess(state, action) {
            state.studioPictures = action.payload;
        },

        // DOWNLOAD STUDIO PICTURE
        downloadStudioPicturesSuccess(state, action) {
            state.studioPictures = action.payload;
        },


        // GET ROOMS
        getRoomForStudioSuccess(state, action) {
            state.rooms = action.payload;
        }
    }
});

const db = getFirestore()


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setStudioLastVisit(studioId, timeLastVisit) {
    return async () => {
        try {

            const respLastVisit = await updateDoc(doc(db, `Studios/${studioId}`), { lastVisit: timeLastVisit });
            // console.log('setStudioLastVisit respLastVisit', respLastVisit)
            // dispatch(slice.actions.setLastVisitSuccess(timeLastVisit));
            // dispatch(slice.actions.studioRefresh(true));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getStudios(userEmail) {
    return async () => {
        try {

            const q = query(collection(db, "Studios"), where("ownerEmail", "==", userEmail));

            const documentSnapshots = await getDocs(q);

            const studios = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                studios.push(data)
            }

            dispatch(slice.actions.getStudiosSuccess(studios));
            dispatch(slice.actions.studioRefresh(false));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllStudios() {
    return async () => {
        try {

            const q = query(collection(db, "Studios"));

            const documentSnapshots = await getDocs(q);

            const studios = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                studios.push(data)
            }

            dispatch(slice.actions.getAllStudiosSuccess(studios));
            // dispatch(slice.actions.studioRefresh(false));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function getRooms(studioId) {
    return async () => {
        try {

            const q = query(collection(db, `Studios/${studioId}/Room`), orderBy("index", "asc"));

            const documentSnapshots = await getDocs(q);

            const rooms = []

            for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                const data = documentSnapshots.docs[i].data()

                rooms.push(data)
            }

            dispatch(slice.actions.getRoomForStudioSuccess(rooms));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addRoom(studioId, room) {
    return async () => {
        try {

            const addRoomResp = await addDoc(collection(db, `Studios/${studioId}/Room`), room);
            if (addRoomResp?.id) await updateDoc(doc(db, `Studios/${studioId}/Room`, addRoomResp.id), { id: addRoomResp?.id });
            dispatch(slice.actions.studioRefresh(true));

        } catch (error) {

            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

export function addStudio(studio) {
    console.log('called')
    return async () => {
        try {

            const addStudoResp = await addDoc(collection(db, "Studios"), studio);
            if (addStudoResp?.id) await updateDoc(doc(db, "Studios", addStudoResp.id), { id: addStudoResp?.id });
            dispatch(slice.actions.studioRefresh(true));

        } catch (error) {

            console.log('error', error)

            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

export function editRoom(studioId, roomId, room) {
    return async () => {
        try {

            const editRoomResp = await updateDoc(doc(db, `Studios/${studioId}/Room`, roomId), room);
            dispatch(slice.actions.studioRefresh(true));
        } catch (error) {
            console.log('error', error)
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

export function editStudio(studio) {
    return async () => {
        try {

            const editStudoResp = await updateDoc(doc(db, `Studios`, studio?.id), studio);
            dispatch(slice.actions.studioRefresh(true));
        } catch (error) {
            console.log('error', error)
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

export function deleteStudio(id) {
    return async () => {
        try {

            const deleteStudoResp = await deleteDoc(doc(db, `Studios/${id}`,));
            // dispatch(slice.actions.editStudioSuccess(response.data.address));
            dispatch(slice.actions.studioRefresh(true));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

export function deleteRoom(studioId, roomId) {
    return async () => {
        try {

            const deleteRoomResp = await deleteDoc(doc(db, `Studios`, `${studioId}`, "Room", `${roomId}`));

            const q = query(collection(db, `Studios/${studioId}/Room`), orderBy("index", "asc"));

            const documentSnapshots = await getDocs(q);

            const rooms = []

            const promiseUpdateDocIndex = []

            if (documentSnapshots.docs.length > 0) {

                for (let i = 0; i < documentSnapshots.docs.length; i += 1) {
                    const data = documentSnapshots.docs[i].data()
   
                    rooms.push({ ...data, index: i + 1 })
                    promiseUpdateDocIndex.push(updateDoc(doc(db, `Studios/${studioId}/Room/${data?.id}`,), { index: i + 1 }))
                }

                Promise.all(promiseUpdateDocIndex).then(values => {
                    dispatch(slice.actions.studioRefresh(true));
                }).catch(e => {

                }).finally(el => {
                    dispatch(slice.actions.studioRefresh(true));
                })
            }


        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

export function addStudioReset() {

    return async () => {

        dispatch(slice.actions.addStudiosReset(false));

    };
}

export function addStudioImagePath(studioId, fileUploadedPath, downloadURLs) {

    return async () => {

        try {

            await updateDoc(doc(db, "Studios", studioId), { picturePathList: fileUploadedPath, urlPathList: downloadURLs });
            dispatch(slice.actions.studioRefresh(true));

        } catch (error) {

            dispatch(slice.actions.hasError(error));

        }

    };
}

export function addRoomImagePath(studioId, roomindex, fileUploadedPath, downloadURLs) {

    return async () => {

        try {

            console.log('dispatch addRoomImagePath roomindex', roomindex)

            const studioRef = collection(db, `Studios/${studioId}/Room`);
            const q = query(studioRef, where("index", "==", roomindex));
            const querySnapshot = await getDocs(q);
            let docId;
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                docId = doc.id
            });

            await updateDoc(doc(db, `Studios/${studioId}/Room`, docId), { picturePathList: fileUploadedPath, urlPathList: downloadURLs });
            dispatch(slice.actions.studioRefresh(true));

        } catch (error) {

            console.log('error', error)
            dispatch(slice.actions.hasError(error));

        }

    };
}

// export function downloadStudioPictures() {



//     return async () => {
//         try {
            
//         } catch (error) {

//             dispatch(slice.actions.hasError(error));
//             // dispatch(slice.actions.addStudiosReset(false));
//         }
//     };
// }


export function addStudioPictures() {
    return async () => {
        try {
            const response = await addDoc(collection(db, "Studios"));
            await db.collection("Studios").doc(response?.id).update({ id: response?.id });
            dispatch(slice.actions.addStudiosSuccess(true));
        } catch (error) {

            dispatch(slice.actions.hasError(error));
            // dispatch(slice.actions.addStudiosReset(false));
        }
    };
}

// TODO : later for searching optimization on customer end
function writeStudioNameIndexData(studioName) {
    const db = getDatabase();
    set(ref(db, `StudioName/${UIDV4()}`), {
        studioName
    });
}




